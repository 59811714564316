import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import moment from 'moment'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const nowYear = moment().format('YYYY')

const Wrapper = styled.footer`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: ${props => props.theme.sizes.maxWidth};
`

const List = styled.ul`
  // display: flex;
  // flex-flow: row wrap;
  // justify-content: space-between;
  display: block;
  align-items: center;
  width: 100%;
  border-top: 1px solid ${props => props.theme.colors.secondary};
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Copyright = styled.div`
  padding: 0.5rem 1rem 1rem;
  img {
    width: 120px;
    margin: auto;
  }
`

const Item = styled.li`
  display: block;
  padding: 0.25em 0;
  width: 100%;
  text-align: center;
  @media screen and (min-width: ${props => props.theme.responsive.small}) {
    width: auto;
  }
  a {
    font-weight: 600;
    transition: all 0.2s;
    color: ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.highlight};
    }
    &:visited {
      color: ${props => props.theme.colors.base};
    }
  }
  span {
    padding: 0.25rem;
  }
  i, svg {
    font-size: 2rem;
  }
  .copyright {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #999;
  }
  .sitelinks {
    text-align: center;
    margin-bottom: 0.5rem;
    > li {
      display: inline-block;
      a {
        padding: 0.25rem;
        text-decoration: none;
        font-size: 0.9rem;
        color: #999;
      }
    }
  }
`

const Footer = () => (
  <Wrapper>
    <List>
      <Item>
        <ul className="sitelinks">
          <li>
            <Link to="/page/terms-of-use/">Terms of Use</Link>
          </li>
          <li>
            <Link to="/page/privacy-policy/">Privacy Policy</Link>
          </li>
        </ul>
      </Item>
      <Item>
        <span>
          <a href="https://www.instagram.com/katekomika">
            <FontAwesomeIcon icon={["fab", "instagram"]} style={{ 'color': '#8a3ab9' }} />
          </a>
        </span>
        <span>
          <a href="https://www.facebook.com/katekomika">
            <FontAwesomeIcon icon={["fab", "facebook"]} style={{ color: '#3b5998'}} />
          </a>
        </span>
      </Item>
      <Item>
        <Copyright className="copyright">
          <img src={'/copyright.jpg'} /><br />
          &copy;Katekomika 2017-{nowYear}.<br />
          All rights reserved. <br/>
          Semua hak dilindungi undang-undang.
        </Copyright>
      </Item>
    </List>
  </Wrapper>
)

export default Footer
