import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import krucils from '../images/krucilsc.png'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Header = styled.header`
  background: ${props => props.theme.colors.base};
  width: 100%;
  // padding: 1.5em 0;
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  margin: 0 auto;
  padding: 0 1.5em;

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  li {
    display: inline-block;
    margin-left: 1em;
    &:first-child {
      position: relative;
      margin: 0;
      // flex-basis: 100%;
    }
  }

  a {
    display: block;
    text-decoration: none;
    color: #fff;
    font-weight: 600;
    transition: all 0.2s;
    border-bottom: 2px solid ${props => props.theme.colors.base};
    &:hover {
      color: ${props => props.theme.colors.secondary};
    }
  }
  .krucilStyle {
    padding: 0.5rem;
    img {
      height: 81px;
      width: auto;
      @media (max-width: 768px) {
        height: 60px;
      }
    }
  }
`

const activeLinkStyle = {
  color: 'white',
}

const Menu = () => {
  return (
    <Header>
      <Nav>
        <ul>
          <li>
            <Link to="/" activeStyle={activeLinkStyle}>
              Home
            </Link>
          </li>
          <li>
            <Link to="/" className={`krucilStyle`}>
              <img src={krucils} alt={`Kate, Koko, Mika`}  />
            </Link>
          </li>
          <li>
            <Link to="/page/about/" activeStyle={activeLinkStyle}>
              About
            </Link>
          </li>
        </ul>
      </Nav>
    </Header>
  )
}

export default Menu
