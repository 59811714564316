module.exports = {
  siteTitle: 'Katekomika',
  siteTitleAlt: 'Katekomika', // This allows an alternative site title for SEO schema.
  publisher: 'Publisher named Katekomika', // Organization name used for SEO schema
  siteDescription:
    'Katekese Komik!',
  siteUrl: 'https://katekomika.netlify.com', // Site domain. Do not include a trailing slash! If you wish to use a path prefix you can read more about that here: https://www.gatsbyjs.org/docs/path-prefix/
  postsPerHomePage: 7, // Number of posts shown on the 1st page of of the index.js template (home page)
  postsPerPage: 6, // Number of posts shown on paginated pages
  author: 'Katekomika User', // Author for RSS author segment and SEO schema
  authorUrl: 'https://www.instagram.com/katekomika/', // URL used for author and publisher schema, can be a social profile or other personal site
  userTwitter: '@katekomika', // Change for Twitter Cards
  shortTitle: 'Katekomika', // Used for App manifest e.g. Mobile Home Screen
  shareImage: '/logo/ogimg.jpg', // Open Graph Default Share Image. 1200x1200 is recommended
  shareImageWidth: 900, // Change to the width of your default share image
  shareImageHeight: 600, // Change to the height of your default share image
  siteLogo: '/logo/logo.png', // Logo used for SEO, RSS, and App manifest
  backgroundColor: '#ffffff', // Used for Offline Manifest
  themeColor: '#ffffff', // Used for Offline Manifest
  copyright: 'Copyright © 2017-2019 Katekomika', // Copyright string for the RSS feed
}
